import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter as Router} from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';

import client from './common/apollo-client';

import {App} from './container';
import { Provider } from 'react-redux';
import configureStore from './store/configureStore';


const renderApp = (App: any) => {

  ReactDOM.render(
    <React.StrictMode>
      <Provider store={configureStore()}>
        <ApolloProvider client={client}>
          <Router>
            <App />
          </Router>
        </ApolloProvider>
      </Provider>
    </React.StrictMode>,
    document.getElementById('root')
  );
};

renderApp(App);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
