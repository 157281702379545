
class DateTimeUtil {
    static getCurrentTimeDBString() : string {
        let dateTime = new Date();

        return `T${dateTime.getHours().toString().padStart(2, '0')}:${dateTime.getMinutes().toString().padStart(2, '0')}:${dateTime.getSeconds().toString().padStart(2, '0')}.000Z`;
    }
}

export default DateTimeUtil;

