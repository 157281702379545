import { FC } from 'react';
import { Channel } from '../../common/interfaces/channel.interface';
import SchedulerGridItem from './scheduler-grid-item/scheduler-grid-item.component';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper'
import './scheduler-grid.component.css';

interface SchedulerGridProps {
    channelData?: Channel[]
}

const SchedulerGrid: FC<SchedulerGridProps> = ({channelData}: SchedulerGridProps) => {

    function checkData() {
        if (channelData?.length == 0) {
            return (
                <p>No data found!</p>
            )
        }
    }

    return (
        <div className="scheduler-grid">
            <Grid container direction="row" justify="flex-start" alignItems="flex-start" wrap="nowrap" zeroMinWidth={true} spacing={1}>
                {checkData()}
                {channelData?.map(channelData => (
                    <div key={channelData.id}>
                        <p className="channel-header" key={channelData.id}>{channelData.name}</p>
                        <div className="spacer"></div>
                        {channelData.epgData?.map((epgData, index) => (
                            <>
                                <SchedulerGridItem key={epgData.id} epgData={epgData} rowNumber={index} />
                            </>
                        ))}
                    </div> 
                ))}
            </Grid>
        </div>
    );
}

export default SchedulerGrid;