import { ChannelProvider } from "../common/interfaces/channelProvider.interface";
import { ChannelType } from "../common/interfaces/channelType.interface";

export const CONFIG_TYPE = "CONFIG_TYPE";

export interface Config {
    date?: Date;
    now?: boolean;
    primetime?: boolean,
    channelProvider?: ChannelProvider
    channelType?: ChannelType;
}

export interface ConfigAction {
    type: string;
    payload: Config | null;
}

export const ConfigReducer = (state: Config | null = null, action: ConfigAction): Config | null => {
    switch(action.type) {
        case CONFIG_TYPE:
            //console.log("config reducer", action.payload);
            return action.payload;
        default:
            return state;
    }
}