  import React, { FC, useEffect } from 'react';
    import './App.css';

  import { GET_ALL_CHANNELS, useGetChannels, useGetChannelsProps } from '../../hooks/channels/useGetChannels';
  //import { gql, useQuery } from '@apollo/client';
  
  import SchedulerGrid from '../../components/scheduler-grid/scheduler-grid.component';
import AppHeader from '../../components/AppHeader/AppHeader';
import { Channel } from '../../common/interfaces/channel.interface';
import { ChannelType } from '../../common/interfaces/channelType.interface';
import { ChannelProvider } from '../../common/interfaces/channelProvider.interface';
import { useLazyQuery, useQuery } from '@apollo/client';
import DateUtil from '../../common/utils/dateTime.util';
import { useSelector } from 'react-redux';
import { AppState } from '../../store/AppState';
import LinearProgress from '@material-ui/core/LinearProgress';
import DateTimeUtil from '../../common/utils/dateTime.util';


  export const App: FC = () => {
    const [sideMenuOpen, setSideMenuOpen] = React.useState(false);
    const APP_TITLE = 'TV Scheduler';
    let channelParams : useGetChannelsProps = {};

    const [getData, {loading, data, error}] = useLazyQuery(GET_ALL_CHANNELS, { fetchPolicy: "cache-and-network" });
    //let channels = useGetChannels(channelParams);

    const config = useSelector((state: AppState) => state.config);

    useEffect(() => {
      document.title = APP_TITLE;

      getData({
        variables: { 
          id: [71, 37, 38, 39, 41, 40, 42, 507],
          starttime: getDBStartDateString(config?.date ?? new Date()),
          endtime: getDBEndDateString(config?.date ?? new Date())
        }
      });
    }, []);

    useEffect(() => {
      // console.log('Query!');
      // console.log({
      //   variables: { 
      //     id: (!config?.channelProvider?.id && !config?.channelType?.id)? [71, 37, 38, 39, 41, 40, 42, 507] : undefined ,
      //     providerId: config?.channelProvider?.id,
      //     typeId: config?.channelType?.id,
      //     starttime: getDBStartDateString(config?.date ?? new Date()), //"2021-04-19T05:00:00.000Z",
      //     endtime: getDBEndDateString(config?.date ?? new Date()),
      //     filter: {
      //       primetime: config?.primetime
      //     }
      //   },
      // });

      getData({
        variables: { 
          id: (!config?.channelProvider?.id && !config?.channelType?.id)? [71, 37, 38, 39, 41, 40, 42, 507] : undefined ,
          providerId: config?.channelProvider?.id,
          typeId: config?.channelType?.id,
          starttime: getDBStartDateString(config?.date ?? new Date()), //"2021-04-19T05:00:00.000Z",
          endtime: getDBEndDateString(config?.date ?? new Date()),
          filter: {
            primetime: config?.primetime
          }
        },
      });
    }, [config]);

    const getDBStartDateString = (date: Date): string => {
      let time = 'T05:00:00.000Z';
      if (config?.now) {
        time = DateTimeUtil.getCurrentTimeDBString();
      }
      
      return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}${time}`;;
    }

    const getDBEndDateString = (date: Date): string => {
      let time = 'T04:59:00.000Z';
      return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${(date.getDate() + 1).toString().padStart(2, '0')}${time}`;;
    }

    const onOpen = (open: boolean) => {
      if (open) {
        setSideMenuOpen(true);
      } else {
        setSideMenuOpen(false);
      }
    }

    const onChannelTypeChange = (channelType: ChannelType) => {
      const toDay = new Date();
      const selectedDate = config?.date ?? new Date();
      getData({
        variables: { 
          typeId: channelType.id,
          starttime: getDBStartDateString(selectedDate), //"2021-04-19T05:00:00.000Z",
          endtime: getDBEndDateString(selectedDate)
        }
      });
    }

    const onChannelProviderChange = (channelProvider: ChannelProvider) => {
      const toDay = new Date();
      const selectedDate = config?.date ?? new Date();
      getData({
        variables: { 
          providerId: channelProvider.id,
          starttime: getDBStartDateString(selectedDate),
          endtime: getDBEndDateString(selectedDate)
        }
      });
    }

    return (
      <div className="App">
        <AppHeader title="TV Scheduler" onOpen={onOpen} onChannelProviderChange={onChannelProviderChange} onChannelTypeChange={onChannelTypeChange}></AppHeader>

        
        <div className={sideMenuOpen ? "SidebarShift" : "Content"}>
          {error && <div>{error.message}</div>}
          {/* <SchedulerGrid channelData={channels}/> */}
          {loading ? <LinearProgress />: null}
          <SchedulerGrid channelData={data?.getChannels}/>
        </div>

        

    {/* {data.Channel?.map((channel: { id: number; }) => (
          <div>{channel.id}</div>
        ))} */}

      </div>
    )
  }
