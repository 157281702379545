import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import React, { FC } from 'react';
import SideMenu from '../SideMenu/SideMenu';
import { ChannelProvider } from '../../common/interfaces/channelProvider.interface';
import { ChannelType } from '../../common/interfaces/channelType.interface';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
      textAlign: "center"
    },
  }),
);

type AppHeaderProps = {
    title: string,
    onOpen: (open: boolean) => void,

    // Temporary
    onChannelProviderChange?: (channelProvider: ChannelProvider) => void,
    onChannelTypeChange?: (channelType: ChannelType) => void,
}

const AppHeader: FC<AppHeaderProps> = ({ title, onOpen, onChannelProviderChange, onChannelTypeChange }: AppHeaderProps) => {
    const classes = useStyles();
    const [auth, setAuth] = React.useState(true);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const [sideMenuOpen, setSideMenuOpen] = React.useState(false);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAuth(event.target.checked);
    };

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDrawerOpen = () => {
        if (sideMenuOpen) {
            setSideMenuOpen(false);
            onOpen(false);
        } else {
            setSideMenuOpen(true);
            onOpen(true);
        }
      };
    
      const handleDrawerClose = () => {
        setSideMenuOpen(false);
      };

    return (
        <div className={classes.root}>
        {/* <FormGroup>
          <FormControlLabel
            control={<Switch checked={auth} onChange={handleChange} aria-label="login switch" />}
            label={auth ? 'Logout' : 'Login'}
          />
        </FormGroup> */}
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" onClick={handleDrawerOpen}>
              {sideMenuOpen ? <ChevronLeftIcon /> : <MenuIcon />}
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {title}
            </Typography>
            {auth && (
              <div>
                <IconButton
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleMenu}
                  color="inherit"
                >
                  <AccountCircle />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                    //   getContentAnchorEl={null}
                  anchorOrigin={{
                    vertical: 'bottom',
                    // horizontal: 'right',
                    horizontal: 'left',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={open}
                  onClose={handleClose}
                >
                  <MenuItem onClick={handleClose} divider>Einstellungen</MenuItem>
                  <MenuItem onClick={handleClose}>Logout</MenuItem>
                </Menu>
              </div>
            )}
          </Toolbar>
        </AppBar>
        <SideMenu isOpen={sideMenuOpen} onChannelProviderChange={onChannelProviderChange} onChannelTypeChange={onChannelTypeChange}></SideMenu>
      </div>
    )
  }

  export default AppHeader