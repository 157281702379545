import React, { FC, MouseEventHandler, useEffect } from 'react';
import IconButton from '@material-ui/core/IconButton';
import HistoryIcon from '@material-ui/icons/History';

import './date-selector.component.css';
import { NavigateBefore, NavigateNext } from '@material-ui/icons';
import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';

interface DateSelectorProps {
    date?: Date,
    onDateChanged?: (date: Date) => void
}

enum WeekDayDescription {
    'So',
    'Mo',
    'Di',
    'Mi',
    'Do',
    'Fr',
    'Sa'
}

const DateSelector: FC<DateSelectorProps> = ({date = new Date(), onDateChanged}: DateSelectorProps) => {
    const [selectedDate, setDate] = React.useState(date);

    useEffect(() => {
        if (onDateChanged) {
            onDateChanged(selectedDate);
        }
    }, [selectedDate])

    const onIncrement = () => {
        setDate(new Date(selectedDate.getFullYear(),selectedDate.getMonth(),selectedDate.getDate() + 1));
    }

    const onDecrement = () => {
        setDate(new Date(selectedDate.getFullYear(),selectedDate.getMonth(),selectedDate.getDate() - 1));
    }

    const onReset = () => {
        setDate(new Date());
    }

    const dateToString = (date: Date): string => {
        return `${WeekDayDescription[date.getDay()]} ${date.getDate().toString().padStart(2, '0')}.${(date.getMonth() + 1).toString().padStart(2, '0')}.${date.getFullYear()}`;
    }

    return (
        <div className="date-selector">
            <span>
            <IconButton color="inherit" size="small" onClick={() => {onDecrement()}}>
                <NavigateBefore />
            </IconButton>
            <Button color="inherit" size="small" onDoubleClick={() => {onReset()}}>{dateToString(selectedDate)}</Button>
            
            <IconButton color="inherit" size="small" onClick={() => {onIncrement()}}>
                <NavigateNext />
            </IconButton>

            <IconButton color="inherit" size="small" onClick={() => {onReset()}}>
                <HistoryIcon />
            </IconButton>
            </span>
        </div>
    );
}

export default DateSelector;