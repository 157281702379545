import { gql, useQuery } from "@apollo/client";
import { ChannelType } from "../../common/interfaces/channelType.interface";

const GET_CHANNELS_TYPES = gql`
  query getChannelTypeList($id: Int) {
    getChannelTypes(id: $id) {
          id, 
          name
      }
  }
`;

export const useGetChannelTypes = (id?: number): ChannelType[] | undefined => {
  const { data, error, loading } = useQuery(GET_CHANNELS_TYPES, {
    variables: { 
      id: id
    }
  });

  if (loading) {
    console.log("is loading...");
    return undefined;
  }
  if (error) {
    console.log(`Error! ${error.message}`);
    return;
  }

  // if (data !== undefined) {
  //   console.log(data.getAllChannels);
  // }
  return data?.getChannelTypes;
}