import { gql, useQuery } from "@apollo/client";
import { Channel } from "../../common/interfaces/channel.interface";
import { ChannelProvider } from "../../common/interfaces/channelProvider.interface";

const GET_CHANNELS_PROVIDERS = gql`
  query getChannelProviderList($id: Int) {
    getChannelProviders(id: $id) {
          id, 
          name
      }
  }
`;

export const useGetChannelProviders = (id?: number): ChannelProvider[] | undefined => {
  const { data, error, loading } = useQuery(GET_CHANNELS_PROVIDERS, {
    variables: { 
      id: id
    }
  });

  if (loading) {
    console.log("is loading...");
    return undefined;
  }
  if (error) {
    console.log(`Error! ${error.message}`);
    return;
  }

  // if (data !== undefined) {
  //   console.log(data.getAllChannels);
  // }
  return data?.getChannelProviders;
}