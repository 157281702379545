import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { createStyles, makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import React, { useEffect, FC } from "react";
import { useGetChannelProviders } from "../../hooks/channelProvider/useGetChannelProviders";
import { useGetChannelTypes } from "../../hooks/channelType/useGetChannelTypes";
import { ChannelType } from "../../common/interfaces/channelType.interface";
import { ChannelProvider } from "../../common/interfaces/channelProvider.interface";
import DateSelector from "../date-selector/date-selector.component";
import './side-menu.component.css';
import { useDispatch } from "react-redux";
import { CONFIG_TYPE } from "../../store/AppConfigReducer";
import Button from "@material-ui/core/Button";
import TvIcon from '@material-ui/icons/Tv';
import MovieIcon from '@material-ui/icons/Movie';
import ScheduleIcon from '@material-ui/icons/Schedule';
import ChannelFilter from "../channel-filter/channel-filter.component";

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    appBar: {
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    hide: {
      display: 'none',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      marginTop: theme.spacing(8),
      justifyContent: 'center',
    },
    drawerPaper: {
      width: drawerWidth,
      marginTop: theme.spacing(8),
      backgroundColor: theme.palette.primary.main,
      zIndex: theme.zIndex.drawer + 1,
      color: 'white',
      //justifyContent: 'center',
      flexShrink: 3 ,
      //alignItems: 'center',
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: 'flex-end',
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: -drawerWidth,
    },
    contentShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    },
    icon: {
      marginRight: 10
    }
  }),
);

type SideMenuProps = {
    isOpen?: boolean,
    onChannelProviderChange?: (channelProvider: ChannelProvider) => void,
    onChannelTypeChange?: (channelType: ChannelType) => void,
}

const SideMenu: FC<SideMenuProps> = ({isOpen, onChannelProviderChange, onChannelTypeChange}: SideMenuProps) => {
    const classes = useStyles();
    const theme = useTheme();
    const [open, setOpen] = React.useState(true);
    const [selectedNow, setSelectedNow] = React.useState(false);
    const [selectedPrimetime, setSelectedPrimetime] = React.useState(false);
    const [selectedDate, setSelectedDate] = React.useState(new Date());
    const [selectedChannelProvider, setSelectedChannelProvider] = React.useState<ChannelProvider>();
    const [selectedChannelType, setSelectedChannelType] = React.useState<ChannelType>();
    const channelProviders = useGetChannelProviders();
    const channelTypes = useGetChannelTypes();
    const dispatch = useDispatch();

    useEffect(() => {
      setConfig();
  }, [selectedNow, selectedPrimetime, selectedDate, selectedChannelProvider, selectedChannelType])

    const setConfig = () => {
      dispatch({
        type: CONFIG_TYPE,
        payload: {
          date: selectedDate,
          now: selectedNow,
          primetime: selectedPrimetime,
          channelProvider: selectedChannelProvider,
          channelType: selectedChannelType,
        }
      });
    };

    const handleDrawerOpen = () => {
        setOpen(true);
      };
    
    const handleDrawerClose = () => {
        setOpen(false);
        isOpen = false;
    };

    const handleNowChange = () => {
      setSelectedNow(!selectedNow);
      setSelectedPrimetime(false);
    }

    const handlePrimetimeChange = () => {
      setSelectedPrimetime(!selectedPrimetime);
      setSelectedNow(false);
    }

    const handleChannelProviderChange = (channelProvider: ChannelProvider) => {
      setSelectedChannelProvider(channelProvider);
      setSelectedChannelType(undefined);
    };

    const handleChannelTypeChange = (channelType: ChannelType) => {
      setSelectedChannelType(channelType);
      setSelectedChannelProvider(undefined);
    };

    const onDateChanged = (date: Date) => {
      setSelectedDate(date);
    }

    useEffect(() => {
        setOpen(isOpen ?? true);
    }, [isOpen])

    return (
        <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"

        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        {/* <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </div>
        <Divider /> */}
        
        {/* <ChannelFilter open={true}></ChannelFilter> */}

        <List>
            <DateSelector onDateChanged={onDateChanged} />
        </List>
        <Divider />
        <List>
          <ListItem button selected={selectedNow}>
            <ScheduleIcon className={classes.icon} />
            <ListItemText primary="Ab jetzt" onClick={() => handleNowChange()} />
          </ListItem>
          <ListItem button selected={selectedPrimetime}>
            <ScheduleIcon className={classes.icon} />
            <ListItemText primary="Primetime" onClick={() => handlePrimetimeChange()} />
          </ListItem>
        </List>
        <List>
          {['Alle Sender', 'Favoriten'].map((text, index) => (
            <ListItem alignItems="center" button key={text}>
              {/* <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon> */}
              <TvIcon className={classes.icon} />
              <ListItemText primary={text} />
            </ListItem>
          ))}
        </List>
        <Divider />
        <List>
          {channelProviders?.map((channelProvider, index) => (
            <ListItem button key={channelProvider.id} selected={selectedChannelProvider?.id === channelProvider.id ? true : false}>
              {/* <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon> */}
              <TvIcon className={classes.icon} />
              <ListItemText primary={channelProvider.name} onClick={() => handleChannelProviderChange(channelProvider)} />
            </ListItem>
          ))}
        </List>
        <Divider />
        <List>
          {channelTypes?.map((channelType, index) => (
            <ListItem button key={channelType.id} selected={selectedChannelType?.id === channelType.id ? true : false}>
              {/* <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon> */}
              <MovieIcon className={classes.icon} />
              <ListItemText primary={channelType.name} onClick={() => handleChannelTypeChange(channelType)} />
            </ListItem>
          ))}
        </List>
        
      </Drawer>
    );
}

export default SideMenu;