import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Rating from '@material-ui/lab/Rating';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React, { FC, useEffect } from 'react';
import { EPGData } from '../../../common/interfaces/epgdata.interface';
import './scheduler-grid-item.component.css';
import Box from '@material-ui/core/Box';
import Grow from '@material-ui/core/Grow';
import LiveTvIcon from '@material-ui/icons/LiveTv';

interface SchedulerGridItemProps {
    epgData?: EPGData,
    rowNumber?: number,
    colNumber?: number
}

const SchedulerGridItem: FC<SchedulerGridItemProps> = ({epgData, rowNumber, colNumber}: SchedulerGridItemProps) => {
    const classes = useStyles();

    function numberToDate(epochDate: number): string {
        let date = new Date(epochDate);
        let hour = date.getUTCHours().toString();
        let minutes: string
        if (date.getUTCMinutes() < 10) {
            minutes = '0' + date.getUTCMinutes().toString();
        } else {
            minutes = date.getUTCMinutes().toString();
        }

        return `${hour}:${minutes}`;
    }

    function getTopMargin() {
        let showStartTime = new Date(Number(epgData?.starttime)); 
        showStartTime.setHours(showStartTime.getHours() - 2); // it's not in correct time

        let startTime = new Date(showStartTime.getFullYear(),showStartTime.getMonth(),showStartTime.getDate(),5,0,0);

        var diff =(showStartTime.getTime() - startTime.getTime()) / 1000;
        diff /= 60;
        diff = Math.abs(Math.round(diff));
        // 1440
        if (showStartTime.getHours() < 5) {
            diff += 1440;
        }

        return diff * 15;
    }



    function currentTime(): boolean {
        
        let dateTime = new Date();

        let startTime = new Date(Number(epgData?.starttime)); 
        startTime.setHours(startTime.getHours() - 2); // it's not in correct time

        let endTime = new Date(Number(epgData?.endtime));
        endTime.setHours(endTime.getHours() - 2); // it's not in correct time
        
        // check if current date and time is between starttime and endtime from broadcast
        if ((dateTime.getTime() >= startTime.getTime()) && (dateTime.getTime() <= endTime.getTime())) {
            return true;
        }
        
        return false;
    }

    return (
        <div className="scheduler-grid-item">
            {epgData !== undefined &&
                <>
                {/* <Grow in={true}>  */}
                    {/* <Box className={`${classes.paper} ${currentTime() ? classes.paperSelected : null}`} style={{ cursor: 'pointer', height: epgData.length * 15 , marginTop: rowNumber == 0 ? getTopMargin() : 0 }} > */}
                    {/* <Box className={`${classes.paper} ${currentTime() ? classes.paperSelected : null}`} style={{ cursor: 'pointer', top: getTopMargin(), position: 'absolute' , marginTop: rowNumber == 0 ? getTopMargin() : 30, height: epgData.length * 25 }} > */}
                    {/* this <Box className={`${classes.paper} ${currentTime() ? classes.paperSelected : null}`} style={{ cursor: 'pointer', top: getTopMargin(), position: 'absolute' , marginTop: rowNumber == 0 ? 30 : 0, height: epgData.length * 25 }} > */}
                    {/* <Box className={`${classes.paper} ${currentTime() ? classes.paperSelected : null}`} style={{ cursor: 'pointer', marginTop: rowNumber == 0 ? getTopMargin() : 0 }} > */}
                    <Box className={`${classes.paper} ${currentTime() ? classes.paperSelected : null}`} style={{ cursor: 'pointer' }} >
                        <Grid container spacing={0} >
                            
                            <Grid item xs={12} sm container spacing={1}>
                                <Grid item className={classes.time}>
                                    <Typography variant="subtitle1">{numberToDate(Number(epgData.starttime))}</Typography>
                                    {currentTime() ? <LiveTvIcon /> : null}
                                </Grid>
                                <Grid item xs container direction="column" spacing={2}>
                                    <Grid item xs>
                                        <Typography gutterBottom variant="subtitle1">
                                            {epgData.title}
                                        </Typography>
                                        <Typography gutterBottom variant="body2">
                                            {epgData?.commentShort != '-' ? epgData?.commentShort : ''}
                                        </Typography>
                                        <img className={classes.img} src={epgData.imageUrl} />
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="body2" >
                                            {epgData?.country ? epgData?.country : ''} {epgData?.year ? epgData?.year : ''} {epgData?.country && epgData?.year ? '-' : '' } {epgData.length} min
                                        </Typography>
                                    </Grid>
                                    {(epgData.rating ?? 0) > 0 &&
                                        <Grid item>
                                            <Rating name="read-only" value={epgData.rating} readOnly />
                                        </Grid>
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                {/* </Grow> */}



                </>
            } 
        </div>
    );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      margin: 'auto',
      maxWidth: 500,
      backgroundColor: '#D2D2D2',
      minWidth: 400
    },
    paperSelected: {
        backgroundColor: '#FFD2D2',
    },
    time: {
      backgroundColor: '#A1A1A1',
      minWidth: 50
    },
    image: {
      width: 32,
      height: 32,
    },
    img: {
      margin: 'auto',
      display: 'block',
      maxWidth: '100%',
      maxHeight: '100%',
    },
  }),
);

export default SchedulerGridItem;