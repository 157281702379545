import { gql, useQuery } from "@apollo/client";
import { Channel } from "../../common/interfaces/channel.interface";



/*
*/

export const GET_ALL_CHANNELS = gql`
  query getChannelList($id: [Int], $providerId: Int, $typeId: Int, $starttime: String, $endtime: String, $orderBy: String, $filter: EPGDataQueryFilter) {
    getChannels(id: $id, providerId: $providerId, typeId: $typeId, starttime: $starttime, endtime: $endtime, orderBy: $orderBy, filter: $filter) {
          id, 
          name, 
          language, 
          country, 
          dvb,
          providerId,
          typeId,
          provider {id, name},
          type {id, name},
          epgData 
          {
            id, 
            epgXmlId,
            broadcastId,
            tvshowId,
            channelId,
            starttime,
            endtime,
            length,
            primetime,
            categoryId,
            technicsBw,
            technicsCoChannel,
            technicsVt150,
            technicsCoded,
            technicsBlind,
            age,
            liveId,
            tipflag,
            title,
            subtitle,
            #commentLong,
            #commentMiddle,
            commentShort,
            genreId,
            sequence,
            technicsStereo,
            technicsDolby,
            technicsWide,
            rating,
            attribute,
            country,
            year,
            moderator,
            studioGuest,
            regisseur,
            actor,
            imageUrl
          }
      }
  }
`;

export interface useGetChannelsProps {
  id?: number[],
  providerId?: number,
  typeId?: number,
  starttime?: string, //"2021-04-13T05:00:00.000Z",
  endtime?: string //"2021-04-14T04:59:00.000Z"  
}

export const useGetChannels = ({id, providerId, typeId, starttime, endtime}: useGetChannelsProps): Channel[] | undefined => {

  const { data, error, loading } = useQuery(GET_ALL_CHANNELS, {
    variables: { 
      id: [37, 38],
      providerId: providerId,
      typeId: typeId,
      starttime: "2021-04-13T05:00:00.000Z",
      endtime: "2021-04-14T04:59:00.000Z"
    }
  });

  if (loading) {
    console.log("is loading...");
    return undefined;
  }
  if (error) {
    console.log(`Error! ${error.message}`);
    return channelData;
  }
  
  // if (data !== undefined) {
  //   console.log(data.getAllChannels);
  // }
  return data?.getChannels;
}



/*
      Sampledata if connection broke:
    */
      const channelData: Channel[] = 
      [
        {
          "id": 38,
          "name": "RTL",
          "language": "",
          "country": "de",
          "dvb": "RTL Television|RTL Austria|RTL HH SH",
          "providerId": null,
          "typeId": null,
          "provider": null,
          "type": null,
          "epgData": [
            {
              "id": 2597,
              "epgXmlId": 4,
              "broadcastId": 161976490,
              "tvshowId": 93043394,
              "channelId": 38,
              "starttime": "1618290300000",
              "endtime": "1618291800000",
              "length": 25,
              "primetime": 0,
              "categoryId": 500,
              "technicsBw": 0,
              "technicsCoChannel": 0,
              "technicsVt150": 0,
              "technicsCoded": 0,
              "technicsBlind": 0,
              "age": 0,
              "liveId": 0,
              "tipflag": 0,
              "title": "Explosiv - Das Magazin",
              "subtitle": "",
              "commentLong": "Das Magazin berichtet auf ganz spezielle Art von den Themen des Tages. Das kann auf amüsante, provokante oder investigative Weise geschehen. Das Wichtigste vom Tage wird aus unterschiedlichsten Blickwinkeln interessant, unterhaltsam und professionell aufbereitet.",
              "commentMiddle": "Das Magazin berichtet auf ganz spezielle Art von den Themen des Tages. Das kann auf amüsante, provokante oder investigative Weise geschehen. Das Wichtigste vom Tage wird aus unterschiedlichsten Blickwinkeln interessant, unterhaltsam und professionell aufbereitet.",
              "commentShort": "Das Magazin berichtet auf ganz spezielle Art von den Themen des Tages. Das kann auf amüsante, provokante oder investigative Weise geschehen. Das Wichtigste vom Tage wird aus unterschiedlichsten Blickwinkeln interessant, unterhaltsam und professionell aufbereitet.",
              "genreId": 561,
              "sequence": 0,
              "technicsStereo": 1,
              "technicsDolby": 0,
              "technicsWide": 0,
              "rating": 0,
              "attribute": 0,
              "country": "D",
              "year": 2021,
              "moderator": "Sandra Kuhn",
              "studioGuest": "",
              "regisseur": "",
              "actor": "",
              "imageUrl": ""
            },
            {
              "id": 2607,
              "epgXmlId": 4,
              "broadcastId": 161976530,
              "tvshowId": 93043427,
              "channelId": 38,
              "starttime": "1618291800000",
              "endtime": "1618302600000",
              "length": 180,
              "primetime": 0,
              "categoryId": 300,
              "technicsBw": 0,
              "technicsCoChannel": 0,
              "technicsVt150": 0,
              "technicsCoded": 0,
              "technicsBlind": 0,
              "age": 0,
              "liveId": 0,
              "tipflag": 0,
              "title": "Guten Morgen Deutschland",
              "subtitle": "",
              "commentLong": "Von montags bis freitags präsentiert das Morgenmagazin live von 6.00 bis 8.30 Uhr aktuelle Nachrichten aus Politik, Sport und Prominenz. Auf die halbstündlichen Nachrichtenblöcke folgt ein ausführlicher Wetterbericht. Nützliche Tipps für Verbraucher, ein Studiotalk mit Klatsch und Tratsch, spannende Aktionen, ein Gewinnspiel und prominente Gäste im Studio sorgen für ein informatives und unterhaltsames Frühstücksfernsehen.",
              "commentMiddle": "Von montags bis freitags präsentiert das Morgenmagazin live von 6.00 bis 8.30 Uhr aktuelle Nachrichten aus Politik, Sport und Prominenz. Auf die halbstündlichen Nachrichtenblöcke folgt ein ausführlicher Wetterbericht. Nützliche Tipps für Verbraucher, ein Studiotalk mit Klatsch und Tratsch, spannende Aktionen, ein Gewinnspiel und prominente Gäste im Studio sorgen für ein informatives und unterhaltsames Frühstücksfernsehen.",
              "commentShort": "Von montags bis freitags präsentiert das Morgenmagazin live von 6.00 bis 8.30 Uhr aktuelle Nachrichten aus Politik, Sport und Prominenz. Auf die halbstündlichen Nachrichtenblöcke folgt ein ausführlicher Wetterbericht. Nützliche Tipps für Verbraucher, ein Studiotalk mit Klatsch und Tratsch, spannende Aktionen, ein Gewinnspiel und prominente Gäste im Studio sorgen für ein informatives und unterhaltsames Frühstücksfernsehen.",
              "genreId": 301,
              "sequence": 0,
              "technicsStereo": 1,
              "technicsDolby": 0,
              "technicsWide": 0,
              "rating": 0,
              "attribute": 0,
              "country": "D",
              "year": 2021,
              "moderator": "Wolfram Kons|Angela Finger-Erben",
              "studioGuest": "",
              "regisseur": "",
              "actor": "",
              "imageUrl": ""
            },
            {
              "id": 2613,
              "epgXmlId": 4,
              "broadcastId": 161976569,
              "tvshowId": 93043393,
              "channelId": 38,
              "starttime": "1618302600000",
              "endtime": "1618304400000",
              "length": 30,
              "primetime": 0,
              "categoryId": 200,
              "technicsBw": 0,
              "technicsCoChannel": 0,
              "technicsVt150": 1,
              "technicsCoded": 0,
              "technicsBlind": 0,
              "age": 12,
              "liveId": 0,
              "tipflag": 0,
              "title": "Gute Zeiten, schlechte Zeiten",
              "subtitle": "Folge 7234",
              "commentLong": "Vor Tobias will Katrin sich keine Blöße geben, doch kaum allein, fällt ihre Fassade in sich zusammen. Tobias trifft unterdessen eine Entscheidung, die ein Comeback mit Katrin in weite Ferne rücken lässt. John und Philip haben Kates Geburtstag gründlich verdorben. Als auch noch Tuner John wütend zur Rede stellt, springt John schließlich über seinen Schatten und sucht Philip auf: Wenn es ihm mit Patrizia ernst ist, dann hat er seinen Segen.",
              "commentMiddle": "Vor Tobias will Katrin sich keine Blöße geben, doch kaum allein, fällt ihre Fassade in sich zusammen. Tobias trifft unterdessen eine Entscheidung, die ein Comeback mit Katrin in weite Ferne rücken lässt. John und Philip haben Kates Geburtstag gründlich verdorben. Als auch noch Tuner John wütend zur Rede stellt, springt John schließlich über seinen Schatten und sucht Philip auf: Wenn es ihm mit Patrizia ernst ist, dann hat er seinen Segen.",
              "commentShort": "Vor Tobias will Katrin sich keine Blöße geben, doch kaum allein, fällt ihre Fassade in sich zusammen. Tobias trifft unterdessen eine Entscheidung, die ein Comeback mit Katrin in weite Ferne rücken lässt. John und Philip haben Kates Geburtstag gründlich verdorben. Als auch noch Tuner John wütend zur Rede stellt, springt John schließlich über seinen Schatten und sucht Philip auf: Wenn es ihm mit Patrizia ernst ist, dann hat er seinen Segen.",
              "genreId": 205,
              "sequence": 7234,
              "technicsStereo": 1,
              "technicsDolby": 0,
              "technicsWide": 0,
              "rating": 0,
              "attribute": 0,
              "country": "D",
              "year": 2021,
              "moderator": "",
              "studioGuest": "",
              "regisseur": "Boris Keidies",
              "actor": "Lennart Borchert (Moritz Bode) - Vildan Cirpan (Nazan Akinci) - Thomas Drechsel (Max 'Tuner' Krüger) - Ulrike Frank (Katrin Flemming) - Chryssanthi Kavazi (Laura Lehmann) - Jan Kittmann (Tobias Evers) - Thaddäus Meilinger (Felix Lehmann) - Anne Menden (Emily Wiedmann) - Jörn Schlönvoigt (Philip Höfer) - Timur Uelker (Nihat Güney) ",
              "imageUrl": ""
            },
            {
              "id": 2590,
              "epgXmlId": 4,
              "broadcastId": 161976462,
              "tvshowId": 93043372,
              "channelId": 38,
              "starttime": "1618304400000",
              "endtime": "1618306200000",
              "length": 30,
              "primetime": 0,
              "categoryId": 200,
              "technicsBw": 0,
              "technicsCoChannel": 0,
              "technicsVt150": 0,
              "technicsCoded": 0,
              "technicsBlind": 0,
              "age": 12,
              "liveId": 0,
              "tipflag": 0,
              "title": "Unter uns",
              "subtitle": "Folge 6587",
              "commentLong": "Dailysoap über den prallen Alltag in der Kölner Schillerallee 10. Die früheren Hausbesitzer, die Familie Weigel, wohnt dort mit anderen Familien und Einzelpersonen sowie einer WG im Dachgeschoss zusammen. Wichtig ist auch, was im Umfeld des Hauses in Arztpraxen, Kiosken oder Kneipen geschieht.",
              "commentMiddle": "Dailysoap über den prallen Alltag in der Kölner Schillerallee 10. Die früheren Hausbesitzer, die Familie Weigel, wohnt dort mit anderen Familien und Einzelpersonen sowie einer WG im Dachgeschoss zusammen. Wichtig ist auch, was im Umfeld des Hauses in Arztpraxen, Kiosken oder Kneipen geschieht.",
              "commentShort": "Dailysoap über den prallen Alltag in der Kölner Schillerallee 10. Die früheren Hausbesitzer, die Familie Weigel, wohnt dort mit anderen Familien und Einzelpersonen sowie einer WG im Dachgeschoss zusammen. Wichtig ist auch, was im Umfeld des Hauses in Arztpraxen, Kiosken oder Kneipen geschieht.",
              "genreId": 205,
              "sequence": 6587,
              "technicsStereo": 1,
              "technicsDolby": 0,
              "technicsWide": 0,
              "rating": 0,
              "attribute": 0,
              "country": "D",
              "year": 2021,
              "moderator": "",
              "studioGuest": "",
              "regisseur": "Michael Harings",
              "actor": "Jan Ammann (Chris Weigel) - Timothy Boldt (Ringo Beckmann) - Jens Hajek (Benedikt Huber) - Benjamin Heinrich (Bambi Hirschberger) - Isabell Hertel (Ute Kiefer) - Tabea Heynig (Britta Schönfeld) - Stefano Ligorio (Gianni Aurino) - Constantin Lücke (Till Weigel) - Luca Maric (Robert Küpper) - Alexander Milo (Jakob Huber) ",
              "imageUrl": ""
            },
            {
              "id": 2606,
              "epgXmlId": 4,
              "broadcastId": 161976527,
              "tvshowId": 93043419,
              "channelId": 38,
              "starttime": "1618306200000",
              "endtime": "1618308000000",
              "length": 30,
              "primetime": 0,
              "categoryId": 200,
              "technicsBw": 0,
              "technicsCoChannel": 0,
              "technicsVt150": 1,
              "technicsCoded": 0,
              "technicsBlind": 0,
              "age": 12,
              "liveId": 0,
              "tipflag": 0,
              "title": "Alles was zählt",
              "subtitle": "Folge 3663",
              "commentLong": "Die wohlhabende Familie Steinkamp besitzt ein Trainingszentrum in Essen, indem auch talentierte Eisläufer für Wettbewerbe vorbereitet werden. Neben Training, Wettkampf und der Leitung des Unternehmens, stehen Liebschaften, Intrigen und Affären im Umfeld der Familie an der Tagesordnung.",
              "commentMiddle": "Die wohlhabende Familie Steinkamp besitzt ein Trainingszentrum in Essen, indem auch talentierte Eisläufer für Wettbewerbe vorbereitet werden. Neben Training, Wettkampf und der Leitung des Unternehmens, stehen Liebschaften, Intrigen und Affären im Umfeld der Familie an der Tagesordnung.",
              "commentShort": "Die wohlhabende Familie Steinkamp besitzt ein Trainingszentrum in Essen, indem auch talentierte Eisläufer für Wettbewerbe vorbereitet werden. Neben Training, Wettkampf und der Leitung des Unternehmens, stehen Liebschaften, Intrigen und Affären im Umfeld der Familie an der Tagesordnung.",
              "genreId": 205,
              "sequence": 3663,
              "technicsStereo": 1,
              "technicsDolby": 0,
              "technicsWide": 0,
              "rating": 0,
              "attribute": 0,
              "country": "D",
              "year": 2021,
              "moderator": "",
              "studioGuest": "",
              "regisseur": "Lutz von Sicherer",
              "actor": "Suri Abbassi (Leyla Öztürk) - Lisandra Bardél (Malu Santos-Albrecht) - Matthias Brüggenolte (Justus Albrecht) - Igor Dolgatschew (Deniz Öztürk) - Sam Eisenstein (Marian Öztürk) - Dominik Flade (Yannick Ziegler) - Alexandra Fonsatti (Chiara Nadolny) - Christopher Kohn (Finn Albrecht) - Lars Korten (Christoph Lukowski) - Tijan Njie (Moritz Brunner) ",
              "imageUrl": ""
            },
            {
              "id": 2594,
              "epgXmlId": 4,
              "broadcastId": 161976481,
              "tvshowId": 93043367,
              "channelId": 38,
              "starttime": "1618308000000",
              "endtime": "1618311600000",
              "length": 60,
              "primetime": 0,
              "categoryId": 200,
              "technicsBw": 0,
              "technicsCoChannel": 0,
              "technicsVt150": 0,
              "technicsCoded": 0,
              "technicsBlind": 0,
              "age": 0,
              "liveId": 0,
              "tipflag": 0,
              "title": "Der Blaulicht Report",
              "subtitle": "Haftbefehl deckt schockierende Lebensumstände auf / Entführte Krankenschwester in Gefahr",
              "commentLong": "-",
              "commentMiddle": "-",
              "commentShort": "-",
              "genreId": 201,
              "sequence": 0,
              "technicsStereo": 1,
              "technicsDolby": 0,
              "technicsWide": 0,
              "rating": 0,
              "attribute": 0,
              "country": "D",
              "year": 2016,
              "moderator": "",
              "studioGuest": "",
              "regisseur": "",
              "actor": "",
              "imageUrl": ""
            }
          ]
        },
        {
          "id": 37,
          "name": "ZDF",
          "language": "",
          "country": "de",
          "dvb": "ZDF",
          "providerId": null,
          "typeId": null,
          "provider": null,
          "type": null,
          "epgData": [
            {
              "id": 1905,
              "epgXmlId": 4,
              "broadcastId": 161912570,
              "tvshowId": 93003710,
              "channelId": 37,
              "starttime": "1618291800000",
              "endtime": "1618304400000",
              "length": 210,
              "primetime": 0,
              "categoryId": 500,
              "technicsBw": 0,
              "technicsCoChannel": 0,
              "technicsVt150": 1,
              "technicsCoded": 0,
              "technicsBlind": 0,
              "age": 0,
              "liveId": 0,
              "tipflag": 0,
              "title": "ARD-Morgenmagazin",
              "subtitle": "",
              "commentLong": "Die morgendliche Nachrichtensendung existiert seit 1992. Im Vordergrund stehen Berichte und Reportagen über tagesaktuelle Themen aus den Bereichen nationale und internationale Politik.",
              "commentMiddle": "Die morgendliche Nachrichtensendung existiert seit 1992. Im Vordergrund stehen Berichte und Reportagen über tagesaktuelle Themen aus den Bereichen nationale und internationale Politik.",
              "commentShort": "Die morgendliche Nachrichtensendung existiert seit 1992. Im Vordergrund stehen Berichte und Reportagen über tagesaktuelle Themen aus den Bereichen nationale und internationale Politik.",
              "genreId": 561,
              "sequence": 0,
              "technicsStereo": 1,
              "technicsDolby": 0,
              "technicsWide": 1,
              "rating": 0,
              "attribute": 0,
              "country": "D",
              "year": 2021,
              "moderator": "Susan Link|Sven Lorig",
              "studioGuest": "",
              "regisseur": "",
              "actor": "",
              "imageUrl": "https://cellular.images.dvbdata.com/6874138/6874138_320x240.jpg"
            },
            {
              "id": 1888,
              "epgXmlId": 4,
              "broadcastId": 161912471,
              "tvshowId": 93014229,
              "channelId": 37,
              "starttime": "1618304400000",
              "endtime": "1618304700000",
              "length": 5,
              "primetime": 0,
              "categoryId": 500,
              "technicsBw": 0,
              "technicsCoChannel": 0,
              "technicsVt150": 1,
              "technicsCoded": 0,
              "technicsBlind": 0,
              "age": 0,
              "liveId": 0,
              "tipflag": 0,
              "title": "heute Xpress",
              "subtitle": "",
              "commentLong": "In dieser Kurzversion der ZDF-Nachrichtensendung werden die Zuschauer über die wichtigsten Ereignisse des Tages aus den Bereichen Politik, Gesellschaft, Wirtschaft und Kultur informiert.",
              "commentMiddle": "In dieser Kurzversion der ZDF-Nachrichtensendung werden die Zuschauer über die wichtigsten Ereignisse des Tages aus den Bereichen Politik, Gesellschaft, Wirtschaft und Kultur informiert.",
              "commentShort": "In dieser Kurzversion der ZDF-Nachrichtensendung werden die Zuschauer über die wichtigsten Ereignisse des Tages aus den Bereichen Politik, Gesellschaft, Wirtschaft und Kultur informiert.",
              "genreId": 566,
              "sequence": 0,
              "technicsStereo": 1,
              "technicsDolby": 0,
              "technicsWide": 1,
              "rating": 0,
              "attribute": 0,
              "country": "D",
              "year": 2021,
              "moderator": "",
              "studioGuest": "",
              "regisseur": "",
              "actor": "",
              "imageUrl": "https://cellular.images.dvbdata.com/2778417/2778417_320x240.jpg"
            },
            {
              "id": 1885,
              "epgXmlId": 4,
              "broadcastId": 161912444,
              "tvshowId": 93014211,
              "channelId": 37,
              "starttime": "1618304700000",
              "endtime": "1618309800000",
              "length": 85,
              "primetime": 0,
              "categoryId": 500,
              "technicsBw": 0,
              "technicsCoChannel": 0,
              "technicsVt150": 1,
              "technicsCoded": 0,
              "technicsBlind": 0,
              "age": 0,
              "liveId": 0,
              "tipflag": 0,
              "title": "Volle Kanne - Service täglich",
              "subtitle": "",
              "commentLong": "Das ZDF-Magazin präsentiert prominente Gäste und viel Service am Morgen. Dazu gibt es Rezepte, Verbraucher- und Gesundheitstipps sowie Trends rund ums Wohnen, um Garten, Sport und Mode.",
              "commentMiddle": "Das ZDF-Magazin präsentiert prominente Gäste und viel Service am Morgen. Dazu gibt es Rezepte, Verbraucher- und Gesundheitstipps sowie Trends rund ums Wohnen, um Garten, Sport und Mode.",
              "commentShort": "Das ZDF-Magazin präsentiert prominente Gäste und viel Service am Morgen. Dazu gibt es Rezepte, Verbraucher- und Gesundheitstipps sowie Trends rund ums Wohnen, um Garten, Sport und Mode.",
              "genreId": 561,
              "sequence": 0,
              "technicsStereo": 1,
              "technicsDolby": 0,
              "technicsWide": 1,
              "rating": 0,
              "attribute": 0,
              "country": "D",
              "year": 2021,
              "moderator": "Nadine Krüger",
              "studioGuest": "Wigald Boning",
              "regisseur": "",
              "actor": "",
              "imageUrl": "https://cellular.images.dvbdata.com/5933070/5933070_320x240.jpg"
            },
            {
              "id": 1893,
              "epgXmlId": 4,
              "broadcastId": 161912521,
              "tvshowId": 71966016,
              "channelId": 37,
              "starttime": "1618309800000",
              "endtime": "1618312500000",
              "length": 45,
              "primetime": 0,
              "categoryId": 200,
              "technicsBw": 0,
              "technicsCoChannel": 0,
              "technicsVt150": 1,
              "technicsCoded": 0,
              "technicsBlind": 0,
              "age": 12,
              "liveId": 0,
              "tipflag": 0,
              "title": "Notruf Hafenkante",
              "subtitle": "Schlaf, Kindchen, schlaf",
              "commentLong": "Im Arbeitsalltag auf dem Hamburger Kiez kreuzen sich oft die Wege der Streifenpolizisten des Polizeikommissariats 21 an der Hamburger Hafenkante und des medizinischen Personals des Elbkrankenhauses. Als Revierleiter sind Martin Berger bzw. Wolf Haller für die Ermittlungen zuständig, während im Krankenhaus Dr. Anna Jacobi, Dr. Jasmin Jonas oder Dr. Philipp Haase Täter sowie Opfer betreuen.",
              "commentMiddle": "Im Arbeitsalltag auf dem Hamburger Kiez kreuzen sich oft die Wege der Streifenpolizisten des Polizeikommissariats 21 an der Hamburger Hafenkante und des medizinischen Personals des Elbkrankenhauses. Als Revierleiter sind Martin Berger bzw. Wolf Haller für die Ermittlungen zuständig, während im Krankenhaus Dr. Anna Jacobi, Dr. Jasmin Jonas oder Dr. Philipp Haase Täter sowie Opfer betreuen.",
              "commentShort": "Im Arbeitsalltag auf dem Hamburger Kiez kreuzen sich oft die Wege der Streifenpolizisten des Polizeikommissariats 21 an der Hamburger Hafenkante und des medizinischen Personals des Elbkrankenhauses. Als Revierleiter sind Martin Berger bzw. Wolf Haller für die Ermittlungen zuständig, während im Krankenhaus Dr. Anna Jacobi, Dr. Jasmin Jonas oder Dr. Philipp Haase Täter sowie Opfer betreuen.",
              "genreId": 211,
              "sequence": 17,
              "technicsStereo": 1,
              "technicsDolby": 0,
              "technicsWide": 1,
              "rating": 0,
              "attribute": 0,
              "country": "D",
              "year": 2010,
              "moderator": "",
              "studioGuest": "",
              "regisseur": "Ulli Baumann",
              "actor": "Christian Tramitz (Peter Leitl) - Rhea Harder-Vennewald (Franzi Jung) - Sanna Englund (Melanie Hansen) - Matthias Schloo (Mattes Seeler) - Peer Jäger (Martin Berger) - Harald Maack (Wolle Wollenberger) - Gerit Kling (Dr. Jasmin Jonas) - Fabian Harloff (Dr. Philipp Haase) - Carin C. Tietze (Regina Krossmann) - Bettina Kupfer (Schwester Agnes) ",
              "imageUrl": "https://cellular.images.dvbdata.com/3434741/3434741_320x240.jpg"
            }
          ]
        }
      
        ];